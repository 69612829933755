import React from 'react';
import PropTypes from 'prop-types';
import Header from 'cccisd-header';
import style from './style.css';

// const Appdefs = window.cccisd.appDefs;

const wordpressUrl = 'https://guhaven.org';

const NavBar = ({ className }) => {
    let primaryNavLinks = [
        {
            url: wordpressUrl,
            label: 'Home',
        },
        { url: `${wordpressUrl}/about`, label: 'About' },
        {
            url: '/all-resources',
            label: 'Resources',
            children: [
                { url: '/all-resources', label: 'All Resources' },
                { url: `${wordpressUrl}/for-youth`, label: 'For Youth' },
                { url: `${wordpressUrl}/for-parents`, label: 'For Parents' },
                { url: `${wordpressUrl}/for-teachers`, label: 'For Teachers' },
                { url: `${wordpressUrl}/for-clinicians`, label: 'For Clinicians' },
            ],
        },
        {
            url: `${wordpressUrl}/get-help`,
            label: 'Get Help',
        },
    ];
    // Dont need the appdef navs right now, since site is not using auth or roles
    // try {
    //     primaryNavLinks.push(Appdefs.navs.find(n => n.handle === 'primaryNav').nav);
    // } catch (e) {
    //     // meh, leave it empty
    // }

    return (
        <Header
            containerClassName={className}
            logoClassName={style.logoPlaceholder}
            primaryNavLinks={primaryNavLinks}
            showAlertAction={false}
            logoLink={wordpressUrl}
            showUserNav={false}
            showHomeAction={false}
            hasAlertAction={false}
        />
    );
};

NavBar.defaultProps = {
    className: 'container',
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
